export const TRANSLATOR_CBPR_MX_MESSAGES = {
    "pacs.002.001.10": {
        "title": "pacs.002.001.10",
        "conversion": "MT199",
        "msg": `<?xml version="1.0" encoding="utf-8"?>
<RequestPayload>
    <AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
        <Fr>
            <FIId>
                <FinInstnId>
                    <BICFI>ABABUS23</BICFI>
                </FinInstnId>
            </FIId>
        </Fr>
        <To>
            <FIId>
                <FinInstnId>
                    <BICFI>AAAAUS29</BICFI>
                </FinInstnId>
            </FIId>
        </To>
        <BizMsgIdr>ABABUS23-STATUS-456/04</BizMsgIdr>
        <MsgDefIdr>pacs.002.001.10</MsgDefIdr>
        <BizSvc>swift.cbprplus.02</BizSvc>
        <MktPrctc>
            <Regy>string</Regy>
            <Id>string</Id>
        </MktPrctc>
        <CreDt>2008-09-29T04:49:45+03:00</CreDt>
        <PssblDplct>true</PssblDplct>
    </AppHdr>
    <Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.002.001.10">
        <FIToFIPmtStsRpt>
            <GrpHdr>
                <MsgId>ABABUS23-STATUS-456/04</MsgId>
                <CreDtTm>2015-06-29T09:56:00+03:00</CreDtTm>
            </GrpHdr>
            <TxInfAndSts>
                <OrgnlGrpInf>
                    <OrgnlMsgId>BBBB/151109-CBJ056</OrgnlMsgId>
                    <OrgnlMsgNmId>pacs.008.001.06</OrgnlMsgNmId>
                    <OrgnlCreDtTm>2015-11-09T10:13:00+03:00</OrgnlCreDtTm>
                </OrgnlGrpInf>
                <OrgnlInstrId>123456789</OrgnlInstrId>
                <OrgnlEndToEndId>VA060327/0123</OrgnlEndToEndId>
                <OrgnlTxId>AAAAUS29/100628/ad458</OrgnlTxId>
                <OrgnlUETR>00000000-0000-4000-8000-000000000000</OrgnlUETR>
                <TxSts>RJCT</TxSts>
                <StsRsnInf>
                    <Orgtr>
                        <Id>
                            <OrgId>
                                <AnyBIC>ABABUS23</AnyBIC>
                            </OrgId>
                        </Id>
                    </Orgtr>
                    <Rsn>
                        <Cd>AM05</Cd>
                    </Rsn>
                </StsRsnInf>
                <InstgAgt>
                    <FinInstnId>
                        <BICFI>ABABUS23</BICFI>
                    </FinInstnId>
                </InstgAgt>
                <InstdAgt>
                    <FinInstnId>
                        <BICFI>AAAAUS29</BICFI>
                    </FinInstnId>
                </InstdAgt>
            </TxInfAndSts>
        </FIToFIPmtStsRpt>
    </Document>
</RequestPayload>`
    },
    "pacs.008.001.08": {
        "title": "pacs.008.001.08",
        "conversion": "MT103",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>BBBBUS33</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>AAAAGB2L</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>BBBB/120928-CCT/JPY/123</BizMsgIdr>
    <MsgDefIdr>pacs.008.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08">
    <FIToFICstmrCdtTrf>
        <GrpHdr>
            <MsgId>BBBB/120928-CCT/JPY/123</MsgId>
            <CreDtTm>2012-09-28T16:00:00+13:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>COVE</SttlmMtd>
                <InstgRmbrsmntAgt>
                    <FinInstnId>
                        <Nm>FIN INST NAME</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </InstgRmbrsmntAgt>
                <InstgRmbrsmntAgtAcct>
                    <Id>
                        <Othr>
                            <Id>ACCOUNTID</Id>
                        </Othr>
                    </Id>
                </InstgRmbrsmntAgtAcct>
                <InstdRmbrsmntAgt>
                    <FinInstnId>
                        <Nm>FIN INST NAME 2</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </InstdRmbrsmntAgt>
                <InstdRmbrsmntAgtAcct>
                    <Id>
                        <Othr>
                            <Id>ACCOUNTID2</Id>
                        </Othr>
                    </Id>
                </InstdRmbrsmntAgtAcct>
                <ThrdRmbrsmntAgt>
                    <FinInstnId>
                        <Nm>FIN INST NAME 3</Nm>
                        <PstlAdr>
                            <AdrLine>ADDRESS 1</AdrLine>
                            <AdrLine>ADDRESS 2</AdrLine>
                        </PstlAdr>
                    </FinInstnId>
                </ThrdRmbrsmntAgt>
                <ThrdRmbrsmntAgtAcct>
                    <Id>
                        <Othr>
                            <Id>ACCOUNTID3</Id>
                        </Othr>
                    </Id>
                </ThrdRmbrsmntAgtAcct>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>BBBB/120928-CCT</InstrId>
                <EndToEndId>ABC/4562/2012-09-08</EndToEndId>
                <TxId>BBBB/120928-CCT/JPY/123/1</TxId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <PmtTpInf>
                <InstrPrty>NORM</InstrPrty>
            </PmtTpInf>
            <IntrBkSttlmAmt Ccy="JPY">100</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <ChrgBr>SHAR</ChrgBr>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <BICFI>INTERBIC</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </IntrmyAgt1>
            <Dbtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICA</AnyBIC>
                    </OrgId>
                </Id>
            </Dbtr>
            <DbtrAcct>
                <Id>
                    <Othr>
                        <Id>00125574999</Id>
                    </Othr>
                </Id>
            </DbtrAcct>
            <DbtrAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </DbtrAgt>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </CdtrAgt>
            <Cdtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICB</AnyBIC>
                    </OrgId>
                </Id>
            </Cdtr>
            <CdtrAcct>
                <Id>
                    <Othr>
                        <Id>23683707994215</Id>
                    </Othr>
                </Id>
            </CdtrAcct>
            <InstrForCdtrAgt>
                <Cd>HOLD</Cd>
            </InstrForCdtrAgt>
            <Purp>
                <Cd>GDDS</Cd>
            </Purp>
            <RmtInf>
                <Strd>
                    <RfrdDocInf>
                        <Tp>
                            <CdOrPrtry>
                                <Cd>CINV</Cd>
                            </CdOrPrtry>
                        </Tp>
                        <Nb>4562</Nb>
                        <RltdDt>2012-09-08</RltdDt>
                    </RfrdDocInf>
                </Strd>
            </RmtInf>
        </CdtTrfTxInf>
    </FIToFICstmrCdtTrf>
</Document>`
    },
    "pacs.008.001.08.stp": {
        "title": "pacs.008.001.08 STP",
        "conversion": "MT103 STP",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>BBBBUS33</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>AAAAGB2L</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>BBBB/120928-CCT/JPY/123</BizMsgIdr>
    <MsgDefIdr>pacs.008.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.stp.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.008.001.08">
    <FIToFICstmrCdtTrf>
        <GrpHdr>
            <MsgId>BBBB/120928-CCT/JPY/123</MsgId>
            <CreDtTm>2012-09-28T16:00:00+13:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>INDA</SttlmMtd>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>BBBB/120928-CCT</InstrId>
                <EndToEndId>ABC/4562/2012-09-08</EndToEndId>
                <TxId>BBBB/120928-CCT/JPY/123/1</TxId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <PmtTpInf>
                <InstrPrty>NORM</InstrPrty>
            </PmtTpInf>
            <IntrBkSttlmAmt Ccy="JPY">100</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <ChrgBr>SHAR</ChrgBr>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <BICFI>INTERBIC</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </IntrmyAgt1>
            <Dbtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICA</AnyBIC>
                    </OrgId>
                </Id>
            </Dbtr>
            <DbtrAcct>
                <Id>
                    <Othr>
                        <Id>00125574999</Id>
                    </Othr>
                </Id>
            </DbtrAcct>
            <DbtrAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </DbtrAgt>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                    <ClrSysMmbId>
                        <ClrSysId>
                            <Cd>ATBLZ</Cd>
                        </ClrSysId>
                        <MmbId>MEMBERID</MmbId>
                    </ClrSysMmbId>
                </FinInstnId>
            </CdtrAgt>
            <Cdtr>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICB</AnyBIC>
                    </OrgId>
                </Id>
            </Cdtr>
            <CdtrAcct>
                <Id>
                    <Othr>
                        <Id>23683707994215</Id>
                    </Othr>
                </Id>
            </CdtrAcct>
            <Purp>
                <Cd>GDDS</Cd>
            </Purp>
        </CdtTrfTxInf>
    </FIToFICstmrCdtTrf>
</Document>`
    },
    "pacs.009.001.08.core": {
        "title": "pacs.009.001.08 CORE",
        "conversion": "MT202",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>AAAABEBBXXX</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>CCCCUS33XXX</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>987</BizMsgIdr>
    <MsgDefIdr>pacs.009.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <CreDt>2021-06-02T11:05:42.864+03:00</CreDt>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08">
    <FICdtTrf>
        <GrpHdr>
            <MsgId>987</MsgId>
            <CreDtTm>2021-06-02T11:05:42.887+03:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>INDA</SttlmMtd>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>987</InstrId>
                <EndToEndId>NOTPROVIDED</EndToEndId>
                <UETR>c8b66b47-2bd9-48fe-be90-93c2096f27d2</UETR>
            </PmtId>
            <IntrBkSttlmAmt Ccy="USD">10500.00</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2009-05-27</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <DbtDtTm>2009-05-27T12:49:00.000+02:00</DbtDtTm>
            </SttlmTmIndctn>
            <PrvsInstgAgt1>
                <FinInstnId>
                    <BICFI>CHASUS33</BICFI>
                </FinInstnId>
            </PrvsInstgAgt1>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>AAAABEBBXXX</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>CCCCUS33XXX</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <BICFI>TESTBICD</BICFI>
                </FinInstnId>
            </IntrmyAgt1>
            <Dbtr>
                <FinInstnId>
                    <BICFI>BKAUATWW</BICFI>
                </FinInstnId>
            </Dbtr>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>TESTBICE</BICFI>
                </FinInstnId>
            </CdtrAgt>
            <Cdtr>
                <FinInstnId>
                    <BICFI>TESTBICF</BICFI>
                </FinInstnId>
            </Cdtr>
        </CdtTrfTxInf>
    </FICdtTrf>
</Document>`
    },
    "pacs.009.001.08.cov": {
        "title": "pacs.009.001.08 COV",
        "conversion": "MT202",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>BBBBUS33</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>CCCCJPJT</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>BBBB/120928-FICT/JPY/430</BizMsgIdr>
    <MsgDefIdr>pacs.009.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.cov.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08">
    <FICdtTrf>
        <GrpHdr>
            <MsgId>BBBB/120928-FICT/JPY/430</MsgId>
            <CreDtTm>2012-09-28T16:00:00+13:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>INDA</SttlmMtd>
                <SttlmAcct>
                    <Id>
                        <Othr>
                            <Id>ACCOUNTID</Id>
                        </Othr>
                    </Id>
                </SttlmAcct>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>BBBB/120928-FICT</InstrId>
                <EndToEndId>ABC/4562/2012-09-08</EndToEndId>
                <TxId>BBBB/120928-CCT/123/1</TxId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <IntrBkSttlmAmt Ccy="JPY">10000000</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <CdtDtTm>2012-09-28T16:00:00+13:00</CdtDtTm>
            </SttlmTmIndctn>
            <SttlmTmReq>
                <CLSTm>12:12:12+13:00</CLSTm>
            </SttlmTmReq>
            <PrvsInstgAgt1>
                <FinInstnId>
                    <BICFI>TESTBICD</BICFI>
                </FinInstnId>
            </PrvsInstgAgt1>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>CCCCJPJT</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <BICFI>INTERBIC</BICFI>
                </FinInstnId>
            </IntrmyAgt1>
            <IntrmyAgt1Acct>
                <Id>
                    <Othr>
                        <Id>INTERAGTACCT</Id>
                    </Othr>
                </Id>
            </IntrmyAgt1Acct>
            <Dbtr>
                <FinInstnId>
                    <BICFI>BBBBUS33</BICFI>
                </FinInstnId>
            </Dbtr>
            <DbtrAcct>
                <Id>
                    <Othr>
                        <Id>DBTRACCT</Id>
                    </Othr>
                </Id>
            </DbtrAcct>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>AAAAJPJT</BICFI>
                </FinInstnId>
            </CdtrAgt>
            <CdtrAgtAcct>
                <Id>
                    <Othr>
                        <Id>CDTRAGTACCT</Id>
                    </Othr>
                </Id>
            </CdtrAgtAcct>
            <Cdtr>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                </FinInstnId>
            </Cdtr>
            <CdtrAcct>
                <Id>
                    <Othr>
                        <Id>CDTRACCT</Id>
                    </Othr>
                </Id>
            </CdtrAcct>
            <UndrlygCstmrCdtTrf>
                <InitgPty>
                    <Nm>ABC Corporation</Nm>
                    <PstlAdr>
                        <StrtNm>Times Square</StrtNm>
                        <BldgNb>7</BldgNb>
                        <PstCd>NY 10036</PstCd>
                        <TwnNm>New York</TwnNm>
                        <Ctry>US</Ctry>
                    </PstlAdr>
                </InitgPty>
                <Dbtr>
                    <Nm>ABC Corporation</Nm>
                    <PstlAdr>
                        <PstCd>NY 10036</PstCd>
                        <TwnNm>New York</TwnNm>
                        <Ctry>US</Ctry>
                    </PstlAdr>
                    <Id>
                        <PrvtId>
                            <DtAndPlcOfBirth>
                                <BirthDt>1994-03-23</BirthDt>
                                <CityOfBirth>Athens</CityOfBirth>
                                <CtryOfBirth>GR</CtryOfBirth>
                            </DtAndPlcOfBirth>
                        </PrvtId>
                    </Id>
                </Dbtr>
                <DbtrAcct>
                    <Id>
                        <Othr>
                            <Id>00125574999</Id>
                        </Othr>
                    </Id>
                </DbtrAcct>
                <DbtrAgt>
                    <FinInstnId>
                        <BICFI>BBBBUS33</BICFI>
                    </FinInstnId>
                </DbtrAgt>
                <DbtrAgtAcct>
                    <Id>
                        <Othr>
                            <Id>DBTRAGTACCT</Id>
                        </Othr>
                    </Id>
                </DbtrAgtAcct>
                <PrvsInstgAgt1>
                    <FinInstnId>
                        <BICFI>TESTBICE</BICFI>
                    </FinInstnId>
                </PrvsInstgAgt1>
                <IntrmyAgt1>
                    <FinInstnId>
                        <BICFI>INTERBIC</BICFI>
                    </FinInstnId>
                </IntrmyAgt1>
                <IntrmyAgt1Acct>
                    <Id>
                        <Othr>
                            <Id>INTERAGTACCT</Id>
                        </Othr>
                    </Id>
                </IntrmyAgt1Acct>
                <CdtrAgt>
                    <FinInstnId>
                        <BICFI>AAAAGB2L</BICFI>
                    </FinInstnId>
                </CdtrAgt>
                <CdtrAgtAcct>
                    <Id>
                        <Othr>
                            <Id>CDTRAGTACCT</Id>
                        </Othr>
                    </Id>
                </CdtrAgtAcct>
                <Cdtr>
                    <Nm>DEF Electronics</Nm>
                    <PstlAdr>
                        <StrtNm>Mark Lane</StrtNm>
                        <BldgNb>55</BldgNb>
                        <PstCd>EC3R7NE</PstCd>
                        <TwnNm>London</TwnNm>
                        <Ctry>GB</Ctry>
                    </PstlAdr>
                </Cdtr>
                <CdtrAcct>
                    <Id>
                        <Othr>
                            <Id>23683707994215</Id>
                        </Othr>
                    </Id>
                </CdtrAcct>
                <RmtInf>
                    <Strd>
                        <RfrdDocInf>
                            <Tp>
                                <CdOrPrtry>
                                    <Cd>CINV</Cd>
                                </CdOrPrtry>
                            </Tp>
                            <Nb>4562</Nb>
                            <RltdDt>2012-09-08</RltdDt>
                        </RfrdDocInf>
                    </Strd>
                </RmtInf>
                <InstdAmt Ccy="USD">23.59</InstdAmt>
            </UndrlygCstmrCdtTrf>
        </CdtTrfTxInf>
    </FICdtTrf>
</Document>`
    },
    "pacs.009.001.08.adv": {
        "title": "pacs.009.001.08 ADV",
        "conversion": "MT202",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICY</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICZ</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>BBBB/120928-FICT/JPY/430</BizMsgIdr>
    <MsgDefIdr>pacs.009.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.adv.02</BizSvc>
    <MktPrctc>
        <Regy>string</Regy>
        <Id>string</Id>
    </MktPrctc>
    <CreDt>2008-09-29T04:49:45+03:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.009.001.08">
    <FICdtTrf>
        <GrpHdr>
            <MsgId>BBBB/120928-FICT/JPY/430</MsgId>
            <CreDtTm>2012-09-28T16:00:00+13:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>COVE</SttlmMtd>
                <InstgRmbrsmntAgt>
                    <FinInstnId>
                        <BICFI>TESTBICA</BICFI>
                    </FinInstnId>
                </InstgRmbrsmntAgt>
                <InstgRmbrsmntAgtAcct>
                    <Id>
                        <Othr>
                            <Id>INSTGRMBRSMNTAGTACCT</Id>
                        </Othr>
                    </Id>
                </InstgRmbrsmntAgtAcct>
                <InstdRmbrsmntAgt>
                    <FinInstnId>
                        <BICFI>TESTBICA</BICFI>
                    </FinInstnId>
                </InstdRmbrsmntAgt>
                <InstdRmbrsmntAgtAcct>
                    <Id>
                        <Othr>
                            <Id>INSTDRMBRSMNTAGTACCT</Id>
                        </Othr>
                    </Id>
                </InstdRmbrsmntAgtAcct>
            </SttlmInf>
        </GrpHdr>
        <CdtTrfTxInf>
            <PmtId>
                <InstrId>BBBB/120928-FICT</InstrId>
                <EndToEndId>ABC/4562/2012-09-08</EndToEndId>
                <TxId>BBBB/120928-CCT/123/1</TxId>
                <UETR>00000000-0000-4000-8000-000000000000</UETR>
            </PmtId>
            <PmtTpInf>
                <InstrPrty>NORM</InstrPrty>
            </PmtTpInf>
            <IntrBkSttlmAmt Ccy="JPY">10000000</IntrBkSttlmAmt>
            <IntrBkSttlmDt>2012-09-29</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <CdtDtTm>2012-09-28T16:00:00+13:00</CdtDtTm>
            </SttlmTmIndctn>
            <SttlmTmReq>
                <CLSTm>12:12:12+13:00</CLSTm>
            </SttlmTmReq>
            <PrvsInstgAgt1>
                <FinInstnId>
                    <BICFI>TESTBICD</BICFI>
                </FinInstnId>
            </PrvsInstgAgt1>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>TESTBICY</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>TESTBICZ</BICFI>
                </FinInstnId>
            </InstdAgt>
            <IntrmyAgt1>
                <FinInstnId>
                    <BICFI>INTERBIC</BICFI>
                </FinInstnId>
            </IntrmyAgt1>
            <IntrmyAgt1Acct>
                <Id>
                    <Othr>
                        <Id>INTERAGTACCT</Id>
                    </Othr>
                </Id>
            </IntrmyAgt1Acct>
            <Dbtr>
                <FinInstnId>
                    <BICFI>TESTBICY</BICFI>
                    <Nm>Debtor Name</Nm>
                    <PstlAdr>
                        <AdrLine>Address</AdrLine>
                    </PstlAdr>
                </FinInstnId>
            </Dbtr>
            <DbtrAcct>
                <Id>
                    <Othr>
                        <Id>DBTRACCT</Id>
                    </Othr>
                </Id>
            </DbtrAcct>
            <DbtrAgt>
                <FinInstnId>
                    <BICFI>DBTRAGNT</BICFI>
                </FinInstnId>
            </DbtrAgt>
            <CdtrAgt>
                <FinInstnId>
                    <BICFI>AAAAJPJT</BICFI>
                </FinInstnId>
            </CdtrAgt>
            <CdtrAgtAcct>
                <Id>
                    <Othr>
                        <Id>CDTRAGTACCT</Id>
                    </Othr>
                </Id>
            </CdtrAgtAcct>
            <Cdtr>
                <FinInstnId>
                    <BICFI>AAAAGB2L</BICFI>
                </FinInstnId>
            </Cdtr>
            <CdtrAcct>
                <Id>
                    <Othr>
                        <Id>CDTRACCT</Id>
                    </Othr>
                </Id>
            </CdtrAcct>
        </CdtTrfTxInf>
    </FICdtTrf>
</Document>`
    },
    "camt.029.001.09": {
        "title": "camt.029.001.09",
        "conversion": "MT196",
        "msg": `<?xml version="1.0" encoding="utf-8"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>UUUUGB2L</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>FFFFFRPP</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>CLOSECNR201203020001</BizMsgIdr>
    <MsgDefIdr>camt.029.001.09</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <MktPrctc>
        <Regy>string</Regy>
        <Id>string</Id>
    </MktPrctc>
    <CreDt>2008-09-29T04:49:45+03:00</CreDt>
    <PssblDplct>true</PssblDplct>
</AppHdr>
<?xml version="1.0" encoding="utf-8"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.029.001.09">
    <RsltnOfInvstgtn>
        <Assgnmt>
            <Id>CLOSECNR201203020001</Id>
            <Assgnr>
                <Agt>
                    <FinInstnId>
                        <BICFI>UUUUGB2L</BICFI>
                    </FinInstnId>
                </Agt>
            </Assgnr>
            <Assgne>
                <Agt>
                    <FinInstnId>
                        <BICFI>FFFFFRPP</BICFI>
                    </FinInstnId>
                </Agt>
            </Assgne>
            <CreDtTm>2012-03-07T11:32:57+01:00</CreDtTm>
        </Assgnmt>
        <Sts>
            <Conf>CNCL</Conf>
        </Sts>
        <CxlDtls>
            <TxInfAndSts>
                <CxlStsId>123456789</CxlStsId>
                <RslvdCase>
                    <Id>123456789</Id>
                    <Cretr>
                        <Pty>
                            <Id>
                                <OrgId>
                                    <AnyBIC>TESTBICA</AnyBIC>
                                </OrgId>
                            </Id>
                        </Pty>
                    </Cretr>
                </RslvdCase>
                <OrgnlGrpInf>
                    <OrgnlMsgId>123456789</OrgnlMsgId>
                    <OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>
                </OrgnlGrpInf>
                <OrgnlUETR>00000000-0000-4000-8000-000000000000</OrgnlUETR>
                <CxlStsRsnInf>
                    <AddtlInf>Test</AddtlInf>
                </CxlStsRsnInf>
            </TxInfAndSts>
        </CxlDtls>
    </RsltnOfInvstgtn>
</Document>`
    },
    "camt.056.001.08": {
        "title": "camt.056.001.08",
        "conversion": "MT192",
        "msg": `<?xml version="1.0" encoding="utf-8"?>
<RequestPayload>
    <AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
        <Fr>
            <FIId>
                <FinInstnId>
                    <BICFI>CCCCGB2L</BICFI>
                </FinInstnId>
            </FIId>
        </Fr>
        <To>
            <FIId>
                <FinInstnId>
                    <BICFI>MMMMGB2L</BICFI>
                </FinInstnId>
            </FIId>
        </To>
        <BizMsgIdr>UTA1030123456789-CANC</BizMsgIdr>
        <MsgDefIdr>camt.056.001.08</MsgDefIdr>
        <BizSvc>swift.cbprplus.02</BizSvc>
        <MktPrctc>
            <Regy>string</Regy>
            <Id>string</Id>
        </MktPrctc>
        <CreDt>2008-09-29T04:49:45+03:00</CreDt>
        <PssblDplct>true</PssblDplct>
    </AppHdr>
    <Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.056.001.08">
        <FIToFIPmtCxlReq>
            <Assgnmt>
                <Id>UTA1030123456789-CANC</Id>
                <Assgnr>
                    <Agt>
                        <FinInstnId>
                            <BICFI>CCCCGB2L</BICFI>
                        </FinInstnId>
                    </Agt>
                </Assgnr>
                <Assgne>
                    <Agt>
                        <FinInstnId>
                            <BICFI>MMMMGB2L</BICFI>
                        </FinInstnId>
                    </Agt>
                </Assgne>
                <CreDtTm>2012-03-24T11:22:59+01:00</CreDtTm>
            </Assgnmt>
            <Undrlyg>
                <TxInf>
                    <Case>
                        <Id>CUSBGB2L-2012032</Id>
                        <Cretr>
                            <Pty>
                                <Id>
                                    <OrgId>
                                        <AnyBIC>CUSBGB2L</AnyBIC>
                                    </OrgId>
                                </Id>
                            </Pty>
                        </Cretr>
                    </Case>
                    <OrgnlGrpInf>
                        <OrgnlMsgId>1030123456789</OrgnlMsgId>
                        <OrgnlMsgNmId>MT103</OrgnlMsgNmId>
                        <OrgnlCreDtTm>2015-11-09T10:13:00+03:00</OrgnlCreDtTm>
                    </OrgnlGrpInf>
                    <OrgnlInstrId>1030123456789</OrgnlInstrId>
                    <OrgnlEndToEndId>123456765</OrgnlEndToEndId>
                    <OrgnlUETR>00000000-0000-4000-8000-000000000000</OrgnlUETR>
                    <OrgnlIntrBkSttlmAmt Ccy="GBP">52317.48</OrgnlIntrBkSttlmAmt>
                    <OrgnlIntrBkSttlmDt>2012-03-23</OrgnlIntrBkSttlmDt>
                    <CxlRsnInf>
                        <Rsn>
                            <Cd>UPAY</Cd>
                        </Rsn>
                    </CxlRsnInf>
                </TxInf>
            </Undrlyg>
        </FIToFIPmtCxlReq>
    </Document>
</RequestPayload>`
    },
    "camt.054.001.08": {
        "title": "camt.054.001.08",
        "conversion": "MT910",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICY</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICZ</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>AAAASESS-FP-00001</BizMsgIdr>
    <MsgDefIdr>camt.054.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>HIGH</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.054.001.08">
    <BkToCstmrDbtCdtNtfctn>
        <GrpHdr>
            <MsgId>AAAASESS-FP-00001</MsgId>
            <CreDtTm>2015-10-18T13:20:00+01:00</CreDtTm>
            <MsgRcpt>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICB</AnyBIC>
                    </OrgId>
                </Id>
            </MsgRcpt>
        </GrpHdr>
        <Ntfctn>
            <Id>AAAASESS-FP-CN-98765</Id>
            <CreDtTm>2015-10-18T13:20:00+01:00</CreDtTm>
            <Acct>
                <Id>
                    <Othr>
                        <Id>50000000054910000003</Id>
                    </Othr>
                </Id>
                <Ccy>SEK</Ccy>
                <Ownr>
                    <Nm>FINPETROL</Nm>
                    <Id>
                        <OrgId>
                            <AnyBIC>TESTBICA</AnyBIC>
                        </OrgId>
                    </Id>
                </Ownr>
                <Svcr>
                    <FinInstnId>
                        <BICFI>TESTBICA</BICFI>
                        <Nm>AAAA BANKEN</Nm>
                        <PstlAdr>
                            <Ctry>SE</Ctry>
                        </PstlAdr>
                    </FinInstnId>
                </Svcr>
            </Acct>
            <Ntry>
                <NtryRef>AAAASESS-FP-CN-9</NtryRef>
                <Amt Ccy="SEK">105678.50</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Sts>
                    <Cd>BOOK</Cd>
                </Sts>
                <BookgDt>
                    <DtTm>2015-10-18T13:15:00+01:00</DtTm>
                </BookgDt>
                <ValDt>
                    <Dt>2015-10-18</Dt>
                </ValDt>
                <AcctSvcrRef>AAAASESS-FP-CN-98765/01</AcctSvcrRef>
                <BkTxCd>
                    <Domn>
                        <Cd>PAYM</Cd>
                        <Fmly>
                            <Cd>0001</Cd>
                            <SubFmlyCd>0005</SubFmlyCd>
                        </Fmly>
                    </Domn>
                </BkTxCd>
                <NtryDtls>
                    <TxDtls>
                        <Refs>
                            <InstrId>MUELL/FINP/RA123</InstrId>
                            <EndToEndId>MUELL/FINP/RA123</EndToEndId>
                        </Refs>
                        <Amt Ccy="SEK">0</Amt>
                        <CdtDbtInd>CRDT</CdtDbtInd>
                        <RltdPties>
                            <Dbtr>
                                <Pty>
                                    <Nm>ABC Corporation</Nm>
                                    <PstlAdr>
                                        <PstCd>NY 10036</PstCd>
                                        <TwnNm>New York</TwnNm>
                                        <Ctry>US</Ctry>
                                    </PstlAdr>
                                    <Id>
                                        <PrvtId>
                                            <DtAndPlcOfBirth>
                                                <BirthDt>1994-03-23</BirthDt>
                                                <CityOfBirth>Athens</CityOfBirth>
                                                <CtryOfBirth>GR</CtryOfBirth>
                                            </DtAndPlcOfBirth>
                                        </PrvtId>
                                    </Id>
                                </Pty>
                            </Dbtr>
                            <DbtrAcct>
                                <Id>
                                    <Othr>
                                        <Id>00125574999</Id>
                                    </Othr>
                                </Id>
                            </DbtrAcct>
                        </RltdPties>
                        <RltdAgts>
                            <DbtrAgt>
                                <FinInstnId>
                                    <BICFI>BBBBUS33</BICFI>
                                </FinInstnId>
                            </DbtrAgt>
                            <IntrmyAgt1>
                                <FinInstnId>
                                    <BICFI>INTERBIC</BICFI>
                                </FinInstnId>
                            </IntrmyAgt1>
                        </RltdAgts>
                        <RltdDts>
                            <TxDtTm>2015-10-18T13:15:00+01:00</TxDtTm>
                        </RltdDts>
                    </TxDtls>
                </NtryDtls>
            </Ntry>
        </Ntfctn>
    </BkToCstmrDbtCdtNtfctn>
</Document>`
    },
    "camt.057.001.06": {
        "title": "camt.057.001.06",
        "conversion": "MT210",
        "msg": `<?xml version="1.0" encoding="utf-8"?>
<RequestPayload>
    <AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
        <Fr>
            <FIId>
                <FinInstnId>
                    <BICFI>CCCCIE2D</BICFI>
                </FinInstnId>
            </FIId>
        </Fr>
        <To>
            <FIId>
                <FinInstnId>
                    <BICFI>BBBBIE2D</BICFI>
                </FinInstnId>
            </FIId>
        </To>
        <BizMsgIdr>EXAMPLE camt.057</BizMsgIdr>
        <MsgDefIdr>camt.057.001.06</MsgDefIdr>
        <BizSvc>swift.cbprplus.02</BizSvc>
        <MktPrctc>
            <Regy>string</Regy>
            <Id>string</Id>
        </MktPrctc>
        <CreDt>2008-09-29T04:49:45+03:00</CreDt>
        <PssblDplct>true</PssblDplct>
    </AppHdr>
    <Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.057.001.06">
        <NtfctnToRcv>
            <GrpHdr>
                <MsgId>EXAMPLE camt.057</MsgId>
                <CreDtTm>2011-01-06T10:00:00+03:00</CreDtTm>
            </GrpHdr>
            <Ntfctn>
                <Id>SAMPLE camt.057</Id>
                <XpctdValDt>2011-01-27</XpctdValDt>
                <Dbtr>
                    <Agt>
                        <FinInstnId>
                            <BICFI>XXXXUS33</BICFI>
                        </FinInstnId>
                    </Agt>
                </Dbtr>
                <Itm>
                    <Id>FX29006/1234</Id>
                    <Amt Ccy="USD">15000000</Amt>
                </Itm>
            </Ntfctn>
        </NtfctnToRcv>
    </Document>
</RequestPayload>`
    },
    "camt.053.001.08": {
        "title": "camt.053.001.08",
        "conversion": "MT940",
        "msg": `<?xml version="1.0" encoding="UTF-8"?>
<RequestPayload>
    <AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
        <Fr>
            <FIId>
                <FinInstnId>
                    <BICFI>TESTBICY</BICFI>
                </FinInstnId>
            </FIId>
        </Fr>
        <To>
            <FIId>
                <FinInstnId>
                    <BICFI>TESTBICZ</BICFI>
                </FinInstnId>
            </FIId>
        </To>
        <BizMsgIdr>AAAASESS-FP-STAT001</BizMsgIdr>
        <MsgDefIdr>camt.053.001.08</MsgDefIdr>
        <BizSvc>swift.cbprplus.02</BizSvc>
        <MktPrctc>
            <Regy>str1234</Regy>
            <Id>str1234</Id>
        </MktPrctc>
        <CreDt>2012-12-13T12:12:12+13:00</CreDt>
        <PssblDplct>true</PssblDplct>
        <Prty>HIGH</Prty>
    </AppHdr>
    <Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.053.001.08">
        <BkToCstmrStmt>
            <GrpHdr>
                <MsgId>AAAASESS-FP-STAT001</MsgId>
                <CreDtTm>2015-10-18T17:00:00+01:00</CreDtTm>
                <MsgRcpt>
                    <Id>
                        <OrgId>
                            <AnyBIC>TESTBICB</AnyBIC>
                        </OrgId>
                    </Id>
                </MsgRcpt>
            </GrpHdr>
            <Stmt>
                <Id>AAAASESS-FP-STAT001</Id>
                <StmtPgntn>
                    <PgNb>1</PgNb>
                    <LastPgInd>true</LastPgInd>
                </StmtPgntn>
                <ElctrncSeqNb>2</ElctrncSeqNb>
                <CreDtTm>2015-10-18T17:00:00+01:00</CreDtTm>
                <FrToDt>
                    <FrDtTm>2015-10-18T08:00:00+01:00</FrDtTm>
                    <ToDtTm>2015-10-18T17:00:00+01:00</ToDtTm>
                </FrToDt>
                <Acct>
                    <Id>
                        <Othr>
                            <Id>50000000054910000003</Id>
                        </Othr>
                    </Id>
                    <Ccy>SEK</Ccy>
                    <Ownr>
                        <Nm>FINPETROL</Nm>
                        <Id>
                            <OrgId>
                                <AnyBIC>TESTBICA</AnyBIC>
                            </OrgId>
                        </Id>
                    </Ownr>
                    <Svcr>
                        <FinInstnId>
                            <BICFI>TESTBICA</BICFI>
                            <Nm>AAAA BANKEN</Nm>
                            <PstlAdr>
                                <Ctry>SE</Ctry>
                            </PstlAdr>
                        </FinInstnId>
                    </Svcr>
                </Acct>
                <Bal>
                    <Tp>
                        <CdOrPrtry>
                            <Cd>OPBD</Cd>
                        </CdOrPrtry>
                    </Tp>
                    <Amt Ccy="SEK">500000</Amt>
                    <CdtDbtInd>CRDT</CdtDbtInd>
                    <Dt>
                        <Dt>2015-10-15</Dt>
                    </Dt>
                </Bal>
                <Bal>
                    <Tp>
                        <CdOrPrtry>
                            <Cd>CLAV</Cd>
                        </CdOrPrtry>
                    </Tp>
                    <Amt Ccy="SEK">5694.23</Amt>
                    <CdtDbtInd>CRDT</CdtDbtInd>
                    <Dt>
                        <Dt>2015-10-18</Dt>
                    </Dt>
                </Bal>
                <Bal>
                    <Tp>
                        <CdOrPrtry>
                            <Cd>FWAV</Cd>
                        </CdOrPrtry>
                    </Tp>
                    <Amt Ccy="SEK">5697.23</Amt>
                    <CdtDbtInd>CRDT</CdtDbtInd>
                    <Dt>
                        <Dt>2015-10-18</Dt>
                    </Dt>
                </Bal>
                <Bal>
                    <Tp>
                        <CdOrPrtry>
                            <Cd>CLBD</Cd>
                        </CdOrPrtry>
                    </Tp>
                    <Amt Ccy="SEK">435678.50</Amt>
                    <CdtDbtInd>CRDT</CdtDbtInd>
                    <Dt>
                        <Dt>2015-10-18</Dt>
                    </Dt>
                </Bal>
                <Ntry>
                    <Amt Ccy="SEK">105678.50</Amt>
                    <CdtDbtInd>CRDT</CdtDbtInd>
                    <Sts>
                        <Cd>BOOK</Cd>
                    </Sts>
                    <BookgDt>
                        <DtTm>2015-10-17T13:15:00+01:00</DtTm>
                    </BookgDt>
                    <ValDt>
                        <Dt>2015-10-18</Dt>
                    </ValDt>
                    <AcctSvcrRef>AAAASESS-FP-CN-98765/01</AcctSvcrRef>
                    <BkTxCd>
                        <Domn>
                            <Cd>PMNT</Cd>
                            <Fmly>
                                <Cd>RCDT</Cd>
                                <SubFmlyCd>BOOK</SubFmlyCd>
                            </Fmly>
                        </Domn>
                        <Prtry>
                            <Cd>NBNK</Cd>
                            <Issr>Issuer</Issr>
                        </Prtry>
                    </BkTxCd>
                    <NtryDtls>
                        <Btch>
                            <PmtInfId>PmtInfId</PmtInfId>
                        </Btch>
                        <TxDtls>
                            <Refs>
                                <InstrId>InstrId</InstrId>
                                <EndToEndId>MUELL/FINP/RA12345</EndToEndId>
                            </Refs>
                            <Amt Ccy="SEK">0</Amt>
                            <CdtDbtInd>CRDT</CdtDbtInd>
                            <RltdPties>
                                <Dbtr>
                                    <Pty>
                                        <Nm>MUELLER</Nm>
                                    </Pty>
                                </Dbtr>
                            </RltdPties>
                            <AddtlTxInf>Additional Info</AddtlTxInf>
                        </TxDtls>
                    </NtryDtls>
                    <AddtlNtryInf>Additional Entry Info is big enough and extra space is required</AddtlNtryInf>
                </Ntry>
            </Stmt>
        </BkToCstmrStmt>
    </Document>
</RequestPayload>`
    },
    "pacs.004.001.09": {
        "title": "pacs.004.001.09",
        "conversion": "MT103 (Return)",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>CCCCIE2D</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>BBBBIE2D</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>CCCC/151122-PR007</BizMsgIdr>
    <MsgDefIdr>pacs.004.001.09</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>NORM</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:pacs.004.001.09">
    <PmtRtr>
        <GrpHdr>
            <MsgId>CCCC/151122-PR007</MsgId>
            <CreDtTm>2015-11-22T10:37:00+03:00</CreDtTm>
            <NbOfTxs>1</NbOfTxs>
            <SttlmInf>
                <SttlmMtd>INDA</SttlmMtd>
            </SttlmInf>
        </GrpHdr>
        <TxInf>
            <RtrId>CCCC/151122-PR007</RtrId>
            <OrgnlGrpInf>
                <OrgnlMsgId>BBBB/151109-CBJ056</OrgnlMsgId>
                <OrgnlMsgNmId>pacs.008.001.08</OrgnlMsgNmId>
                <OrgnlCreDtTm>2015-11-09T10:13:00+03:00</OrgnlCreDtTm>
            </OrgnlGrpInf>
            <OrgnlInstrId>BBBB/151109-CBJ0</OrgnlInstrId>
            <OrgnlEndToEndId>CROPS/SX-25T/2015-10-13</OrgnlEndToEndId>
            <OrgnlTxId>AAAA/151109-CCT/EUR443/1</OrgnlTxId>
            <OrgnlUETR>00000000-0000-4000-8000-000000000000</OrgnlUETR>
            <OrgnlIntrBkSttlmAmt Ccy="EUR">74900</OrgnlIntrBkSttlmAmt>
            <OrgnlIntrBkSttlmDt>2015-11-09</OrgnlIntrBkSttlmDt>
            <RtrdIntrBkSttlmAmt Ccy="EUR">74750</RtrdIntrBkSttlmAmt>
            <IntrBkSttlmDt>2015-11-22</IntrBkSttlmDt>
            <SttlmTmIndctn>
                <DbtDtTm>2012-09-28T16:00:00+13:00</DbtDtTm>
            </SttlmTmIndctn>
            <RtrdInstdAmt Ccy="EUR">74850</RtrdInstdAmt>
            <ChrgBr>SHAR</ChrgBr>
            <ChrgsInf>
                <Amt Ccy="EUR">100</Amt>
                <Agt>
                    <FinInstnId>
                        <BICFI>CCCCIE2D</BICFI>
                    </FinInstnId>
                </Agt>
            </ChrgsInf>
            <InstgAgt>
                <FinInstnId>
                    <BICFI>CCCCIE2D</BICFI>
                </FinInstnId>
            </InstgAgt>
            <InstdAgt>
                <FinInstnId>
                    <BICFI>BBBBIE2D</BICFI>
                </FinInstnId>
            </InstdAgt>
            <RtrChain>
                <Dbtr>
                    <Pty>
                        <Id>
                            <OrgId>
                                <AnyBIC>TESTBICB</AnyBIC>
                            </OrgId>
                        </Id>
                    </Pty>
                </Dbtr>
                <Cdtr>
                    <Pty>
                        <Id>
                            <OrgId>
                                <AnyBIC>TESTBICA</AnyBIC>
                            </OrgId>
                        </Id>
                    </Pty>
                </Cdtr>
            </RtrChain>
            <RtrRsnInf>
                <Rsn>
                    <Cd>NARR</Cd>
                </Rsn>
                <AddtlInf>RETURN AFTER ACCEPTED PAYMENT CANCELLATION REQUEST</AddtlInf>
            </RtrRsnInf>
            <OrgnlTxRef>
                <Dbtr>
                    <Pty>
                        <Id>
                            <OrgId>
                                <AnyBIC>TESTBICA</AnyBIC>
                            </OrgId>
                        </Id>
                    </Pty>
                </Dbtr>
                <DbtrAcct>
                    <Id>
                        <Othr>
                            <Id>00125574999</Id>
                        </Othr>
                    </Id>
                </DbtrAcct>
                <DbtrAgt>
                    <FinInstnId>
                        <BICFI>BBBBUS33</BICFI>
                        <ClrSysMmbId>
                            <ClrSysId>
                                <Cd>ATBLZ</Cd>
                            </ClrSysId>
                            <MmbId>MEMBERID</MmbId>
                        </ClrSysMmbId>
                    </FinInstnId>
                </DbtrAgt>
                <CdtrAgt>
                    <FinInstnId>
                        <BICFI>AAAAGB2L</BICFI>
                        <ClrSysMmbId>
                            <ClrSysId>
                                <Cd>ATBLZ</Cd>
                            </ClrSysId>
                            <MmbId>MEMBERID</MmbId>
                        </ClrSysMmbId>
                    </FinInstnId>
                </CdtrAgt>
                <Cdtr>
                    <Pty>
                        <Id>
                            <OrgId>
                                <AnyBIC>TESTBICB</AnyBIC>
                            </OrgId>
                        </Id>
                    </Pty>
                </Cdtr>
                <CdtrAcct>
                    <Id>
                        <Othr>
                            <Id>23683707994215</Id>
                        </Othr>
                    </Id>
                </CdtrAcct>
            </OrgnlTxRef>
        </TxInf>
    </PmtRtr>
</Document>`
    },
    "camt.052.001.08": {
        "title": "camt.052.001.08",
        "conversion": "MT941",
        "msg": `<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<AppHdr xmlns="urn:iso:std:iso:20022:tech:xsd:head.001.001.02">
    <Fr>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICY</BICFI>
            </FinInstnId>
        </FIId>
    </Fr>
    <To>
        <FIId>
            <FinInstnId>
                <BICFI>TESTBICZ</BICFI>
            </FinInstnId>
        </FIId>
    </To>
    <BizMsgIdr>AAAASESS-FP-ACCR001</BizMsgIdr>
    <MsgDefIdr>camt.052.001.08</MsgDefIdr>
    <BizSvc>swift.cbprplus.02</BizSvc>
    <MktPrctc>
        <Regy>str1234</Regy>
        <Id>str1234</Id>
    </MktPrctc>
    <CreDt>2012-12-13T12:12:12+13:00</CreDt>
    <PssblDplct>true</PssblDplct>
    <Prty>HIGH</Prty>
</AppHdr>
<?xml version="1.0" encoding="UTF-8" standalone="yes"?>
<Document xmlns="urn:iso:std:iso:20022:tech:xsd:camt.052.001.08">
    <BkToCstmrAcctRpt>
        <GrpHdr>
            <MsgId>AAAASESS-FP-ACCR001</MsgId>
            <CreDtTm>2015-10-18T12:30:00+01:00</CreDtTm>
            <MsgRcpt>
                <Id>
                    <OrgId>
                        <AnyBIC>TESTBICB</AnyBIC>
                    </OrgId>
                </Id>
            </MsgRcpt>
        </GrpHdr>
        <Rpt>
            <Id>AAAASESS-FP-ACCR</Id>
            <RptPgntn>
                <PgNb>3</PgNb>
                <LastPgInd>true</LastPgInd>
            </RptPgntn>
            <ElctrncSeqNb>2</ElctrncSeqNb>
            <CreDtTm>2015-10-18T12:30:00+01:00</CreDtTm>
            <FrToDt>
                <FrDtTm>2015-10-18T08:00:00+01:00</FrDtTm>
                <ToDtTm>2015-10-18T12:30:00+01:00</ToDtTm>
            </FrToDt>
            <Acct>
                <Id>
                    <Othr>
                        <Id>50000000054910000003</Id>
                    </Othr>
                </Id>
                <Ccy>SEK</Ccy>
                <Ownr>
                    <Nm>FINPETROL</Nm>
                    <Id>
                        <OrgId>
                            <AnyBIC>TESTBICA</AnyBIC>
                        </OrgId>
                    </Id>
                </Ownr>
                <Svcr>
                    <FinInstnId>
                        <BICFI>TESTBICA</BICFI>
                        <Nm>AAAA BANKEN</Nm>
                        <PstlAdr>
                            <Ctry>SE</Ctry>
                        </PstlAdr>
                    </FinInstnId>
                </Svcr>
            </Acct>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>OPBD</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">500000</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-15</Dt>
                </Dt>
            </Bal>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>CLBD</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">435678.50</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-18</Dt>
                </Dt>
            </Bal>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>CLAV</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">5694.23</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-18</Dt>
                </Dt>
            </Bal>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>FWAV</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">5697.23</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-18</Dt>
                </Dt>
            </Bal>
            <Bal>
                <Tp>
                    <CdOrPrtry>
                        <Cd>FWAV</Cd>
                    </CdOrPrtry>
                </Tp>
                <Amt Ccy="SEK">5698.23</Amt>
                <CdtDbtInd>CRDT</CdtDbtInd>
                <Dt>
                    <Dt>2015-10-18</Dt>
                </Dt>
            </Bal>
            <TxsSummry>
                <TtlCdtNtries>
                    <NbOfNtries>3</NbOfNtries>
                    <Sum>50.26</Sum>
                </TtlCdtNtries>
                <TtlDbtNtries>
                    <NbOfNtries>11</NbOfNtries>
                    <Sum>54.26</Sum>
                </TtlDbtNtries>
            </TxsSummry>
            <AddtlRptInf>Additional Info</AddtlRptInf>
        </Rpt>
    </BkToCstmrAcctRpt>
</Document>`
    }
}